import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
  Span,
} from "../../components/Core";
import { rgba } from "polished";
import { device } from "../../utils";
import Link from 'gatsby-link';
import imgL2HeroOval1 from "../../assets/image/jpeg/d1.jpg";
import imgL2HeroOval2 from "../../assets/image/png/l2-hero-oval2.png";
import imgL2HeroBg from "../../assets/image/jpeg/dermamina-clinic-treatments.jpg";
import imgL3HeroBg from "../../assets/image/jpeg/non-surgical-treatments.jpg";
import nonsurgical from "../../assets/image/jpeg/map.jpg";
import trust from "../../assets/image/png/trust.svg";
import Himage from '../../assets/image/jpeg/home-bg2.jpg';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const Secti = styled.section`

padding-top:6em;
position: relative;

background-color: #ffffff;


@media screen and (max-width: 767px)
{
  padding-top:2em;
 }

`;





const ImgContainer = styled.div`
  margin-top: 20px;
  @media ${device.lg} {
    margin-top: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;
const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;






const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.12;


`;



const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Items = styled.div`
@media ${device.lg} {
flex: 0 0 50%; margin-right: 20px;
}
`;

const Center = styled.div`
@media ${device.lg} {
display: flex;
flex-direction: row;
margin-right: 10px;
align-items: stretch;}

`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0;
  padding-left: 0;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 10px;
      margin-right: 13px;
    }
  }
`;


const SectionStyled = styled(Section)`
background: url(${nonsurgical}) no-repeat;
background-size: cover;
background-position: center center;
  width: 100%;
  height:300px;

  @media ${device.lg} {
    background: url(${nonsurgical}) no-repeat;
    background-size: cover;
    background-position: center center;
      width: 100%;
    height:600px;
  }
`;


const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-bottom:80px;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;
  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }
  &.main {
    background: #154147;
    color: #efe8de;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

const ImageTop = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: -11%;
  left: -25%;
  border-radius: 50%;
  overflow: hidden;
  @media ${device.lg} {
    left: 10%;
  }
`;

const ImageBottom = styled(Box)`
  position: absolute;
  right: -13%;
  top: 54%;
  @media ${device.lg} {
    right: -23%;
  }
  @media ${device.xl} {
    right: -28%;
  }
`;

const HeroConPage = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Secti>
         <Section className="pb-0 pt-5 position-relative">
         
         <Container className="pb-md-5 pb-0 mb-0 pt-md-5">
           <Row><Col lg="6">
         <Iwrap>


<Itext>Contact Dermamina</Itext>
</Iwrap>
      <Secondtext>
     We look forward to assisting you!
      </Secondtext>
      </Col>
      
      
      <Col lg="6" className=" position-static order-lg-2 mb-0 pb-0 pr-0 mr-0 pl-0 pr-0">
            <ImgContainer className=" pl-lg-5">
          <a href="https://goo.gl/maps/5ESi9aJoCdQgUK9d6" target="_blank"> <img
                src={nonsurgical}
                alt="non surgical nose job london"
                className="img-fluid"
                width="800px"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
                css={`
                  box-shadow: ${({ theme }) =>
                    `0 12px 84px ${theme.colors.shadow}`};
                  border-radius: 0px;
                `}
              /></a>
            </ImgContainer>
          </Col>
      
      </Row>
      </Container>
      </Section>
         </Secti>
    </>
  );
};

export default HeroConPage;