// Import necessary libraries
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Derma from '../assets/image/jpeg/dermamina-location.jpg';
import { Section, Text } from "../components/Core";
// Styled components for custom styling
const TextContainer = styled.div`
@media screen and (min-width: 800px) {
    
 
background: #f7f7fb;  // Adjust the background color as needed
  padding-left: 80px;
  position: relative;
  z-index: 1;  // Ensure the text container is above the image
  display: inline-block;

  // Adjust position and dimensions
  &:before {
    content: '';
    position: absolute;
    top: -40px;
    left: 0px;
    right: -80px;
    bottom: -40px;
    background: #f7f7fb;
    z-index: -1;
  }
} 
`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    justify-content: flex-start;
 
  }

  @media screen and (max-width: 991px) {
   
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
    margin-top:20px;
  }
`;

const SecondText = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px !important;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    display:block
  }
`;

const CustomRow = styled(Row)`
  position: relative;
  z-index: 0;
  min-height: 100%;  // Ensure the row takes full viewport height
  @media screen and (max-width: 800px) {
    min-height:100%;
  }
`;

// Main component
const TwoColumnLayout = () => {
  return (
    <>
    <Section py={4} bg="#ffffff" className="pt-lg-5 pb-lg-5 mb-lg-4 mt-0 mb-0 pt-0 pb-0">
    <Container className="pt-4 pb-4 "    data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
          <Row className="align-items-center d-block d-sm-none">
            <Col md="9" lg="12" className="">
      
                   <Iwrap>


<Itext as="h2">Non Surgical Treatments <span>London</span></Itext>
</Iwrap>
      <SecondText>
 How to find us?
      </SecondText>


            
     
            </Col>
            </ Row>
          
      <CustomRow data-aos="fade-up"
    data-aos-duration="500"
    data-aos-once="false"
    data-aos-delay="100" className="align-items-center">
          <Col className='order-lg-2 pb-4 pb-lg-0' md={6}>
          <ImageContainer>
            <img src={Derma} alt="Dermamina London" className='shadow rounded' style={{ width: '100%', height: 'auto' }}  />
          </ImageContainer>
        </Col>
      <Col  md={6} className="d-flex justify-content-center order-lg-1">
     
          <TextContainer>
          <div className="d-none d-sm-block">
          <Iwrap>


<Itext as="h2">Non Surgical Treatments <span>London</span></Itext>
</Iwrap>
      <SecondText>
 How to find us?
      </SecondText>
      </div>
         <Text>  Dermamina is based in the heart of London at 114 New Cavendish Street, just off Great Portland Street and the Goodge Street. <br /> <br />

We are accessible by 6 underground stations all within a 5 minute walk, including Oxford Circus underground station. We are just a 4 minute walk from Harley Street. </Text>

          </TextContainer>
        </Col>
      
      </CustomRow>
    </Container>
    </Section>
    </>
  );
};

export default TwoColumnLayout;
